import React, { useEffect, useState } from 'react';
import useAssetPreloader from 'shared/components/AssetPreloader/useAssetPreloader';
import { ASSET_KEY, assetSource } from 'shared/utils/assetSource';
import Utils from 'shared/utils/index';
import { getText, TEXT_KEY } from 'shared/utils/localization';
import styled from 'styled-components';
import SplashScreenFooter from './components/SplashScreenFooter';
import SplashScreenLoader from './components/SplashScreenLoader';

const HriPreloader = () => {
  const [showLogo, setShowLogo] = useState(true);
  const { percentLoaded } = useAssetPreloader(showLogo);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogo(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [showLogo]);

  return (
    <>
      <SplashScreenLoader showLogo={showLogo} />
      <div
        className="AssetPreloader"
        style={{
          backgroundImage: `url(${assetSource(ASSET_KEY.LOADING_SCREEN)})`,
        }}
      >
        <LoadingBarContainer className={Utils.ieVersion() !== 0 ? ' ieHack' : ''}>
          <LoadingBarBacking src={assetSource(ASSET_KEY.LOADING_BAR_BACKING)} alt="" />
          <LoadingBarFillContainer>
            <LoadingBarFillSide src={assetSource(ASSET_KEY.LOADING_FILL_SIDE)} alt="" />
            <LoadingBarFillInner
              src={assetSource(ASSET_KEY.LOADING_FILL_CENTER)}
              percentLoaded={percentLoaded}
              alt=""
            />
            <LoadingBarFillSide src={assetSource(ASSET_KEY.LOADING_FILL_SIDE)} transform="true" alt="" />
          </LoadingBarFillContainer>
        </LoadingBarContainer>
        <Container>
          <Description>
            {getText(TEXT_KEY.SPLASH_SCREEEN_PRELOADER_DESCRIPTION)
              .split('/')
              .map((text, index) => (
                <TextColor key={index} color={index % 2 === 0 ? '#f3da57' : '#fffad4'}>
                  {text}
                </TextColor>
              ))}
          </Description>
        </Container>
      </div>
      <SplashScreenFooter />
    </>
  );
};

export default HriPreloader;

const LoadingBarContainer = styled.div`
  position: absolute;
  height: 7rem;
  width: 40rem;
  top: 38%;
  overflow: hidden;
  border-radius: 4em;
`;

const LoadingBarBacking = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
`;

const LoadingBarFillContainer = styled.div`
  display: flex;
  flex-direction: row;
  left: 0.25vw;
  position: absolute;
  height: 6.6rem;
  width: 98%;
  align-items: center;
`;

const LoadingBarFillSide = styled.img`
  height: 100%;
  transform: ${({ transform }) => (transform ? 'rotateY(180deg)' : 'none')};
`;

const LoadingBarFillInner = styled.img`
  height: 100%;
  width: ${({ percentLoaded }) => (percentLoaded > 3 ? percentLoaded - 10 : 0)}%;
  transition: all 1s ease;
  margin-inline: -0.8px;
`;

const Container = styled.div`
  margin-top: 15rem;
  width: 41rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  top: 0;
`;

const Description = styled.h4`
  font-size: 2.55rem;
  text-transform: uppercase;
  font-weight: 700;
  text-shadow: 0px 3px 1px #1d062b;
`;

const TextColor = styled.span`
  color: ${({ color }) => color};
`;

